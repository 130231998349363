import React from 'react'
import PropTypes from 'prop-types';

export default function Status({ status }) {
    if (status) {
        return <span className="text-success">Activated</span>
    } else {
        return <span className="text-danger">Inactivated</span>
    }
}
Status.propTypes = {
    status: PropTypes.string.isRequired
};
