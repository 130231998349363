import { POST, GET, PUT, DELETE } from "../baseapi";
const routes = {
  verify_import: "/api/admin/residence-type/verify-import",
  data_import: "/api/admin/residence-type/data-import",
  root_path: "/api/admin/residence-type",
};

/*** Example data ***/
//  const importData = [
//     {
//         residence_en: residenceEn,
//         residence_th: residenceth,
//     }
// ]
export const dataVerify = async (importData) => {
  const url = routes.verify_import;
  const jsonData = {
    importData: importData,
  };
  const result = await POST(url, jsonData);

  return result;
};

export const dataImport = async (data) => {
  const url = routes.data_import;
  const result = await POST(url, data);

  return result;
};
/********************* */

export const getLists = async (query) => {
  const url = routes.root_path;
  const result = GET(url, query || {});

  return result;
};

export const create = async (query) => {
  const url = routes.root_path;
  const result = POST(url, query);

  return result;
};

export const getListById = async (query) => {
  const url = routes.root_path + "/" + query?.uuid;
  console.log("url : ", url);
  // const result = GET(url, query || {});
  const result = GET(url, {});
  return result;
};

export const updateById = async (id, query) => {
  const url = routes.root_path + "/" + id;
  const result = PUT(url, query);

  return result;
};

export const removeById = async (query) => {
  const url = routes.root_path + "/" + query?.uuid;
  const result = await DELETE(url);
  return result;
};
