import { useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import Swal from "sweetalert2";
import "../Sweetalert/sweetalert.css";
import iconSuccess from "../Sweetalert/icon-img/ic-success.png";
import iconWarning from "../Sweetalert/icon-img/ic-warning.png";
import iconError from "../Sweetalert/icon-img/ic-error.png";

function CustomSweetAlert({ data }) {
  useEffect(() => {
    if (data) {
      Swal.fire({
        title: data.title === "ERROR" ? "เกิดข้อผิดพลาด" : "",
        text: data.message,
        imageUrl: data.icon
          ? ""
          : {
              SUCCESS: iconSuccess,
              WARNING: iconWarning,
            }[data.title] || iconError, // Fallback to error icon if no icon found
        imageWidth: 70,
        imageHeight: 70,
        icon: data.icon,
        showCancelButton: data.showCancel,
        confirmButtonText:
          data.confirmBtnText === "verify" ? "ตกลง" : data.confirmBtnText,
        cancelButtonText: data.cancelBtnText,
        confirmButtonColor: data.colorBtnConfirm,
        cancelButtonColor: data.colorBtnCancel,
        reverseButtons: data.reverseButtons,
        customClass: {
          popup: "my-swal-popup",
        },
        didOpen: () => {
          // Set custom color for the popup
          document
            .querySelector(".my-swal-popup")
            ?.style.setProperty("--custom-color", data.colorBorder);
        },
      }).then((result) => {
        if (result.isConfirmed) {
          data.onConfirm(
            data.confirmBtnText === "ตกลง" && data.title === "SUCCESS"
              ? "Close"
              : "Confirmed"
          );
        } else if (result.isDismissed) {
          data.onCancel("Cancelled");
        }
      });
    }
  }, [data]);

  return null; // The alert is triggered programmatically
}

CustomSweetAlert.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    icon: PropTypes.string,
    showCancel: PropTypes.bool,
    confirmBtnText: PropTypes.string,
    cancelBtnText: PropTypes.string,
    colorBtnConfirm: PropTypes.string,
    colorBtnCancel: PropTypes.string,
    reverseButtons: PropTypes.bool,
    colorBorder: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }).isRequired,
};

export default CustomSweetAlert;
