import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../template/Header";
import Menu from "../../template/Menu";
import HeadTitle from "../../template/HeadTitle";
import Footer from "../../template/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { DataDropdownStatus } from "../../components/dataMockup/DataMockDropdown";
import DropdownSearch from "../../components/DropdownSearch/DropdownSearch.jsx";
import { Card } from "react-bootstrap";
import InputData from "../../components/Input/index";
import {
  colorSearchBtn,
  colorResetBtn,
  iconSearchBtn,
  iconResetBtn,
  hoverSearchBtn,
  hoverResetBtn,
} from "../../components/ThemeConfig/btnConfig";
import { useButtonDynamicStyles } from "../../components/useDynamic/Button_DynamicStyle";
// Example fetch
import { getLists as fetchData } from "../../api/unitRoutes";
import ButtonEdit from "../../components/button/edit/Edit.jsx";
import TableContent from "../../components/TableContent/TableContent.jsx";
import Status from "src/components/status/Status"

const buttonConfig = {
  colorSearchBtn: colorSearchBtn,
  colorResetBtn: colorResetBtn,
  iconSearchBtn: iconSearchBtn,
  iconResetBtn: iconResetBtn,
  hoverSearchBtn: hoverSearchBtn,
  hoverResetBtn: hoverResetBtn,
};
export default function MasterData() {
  const { uuid1, uuid2, uuid3 } = useParams();
  useButtonDynamicStyles(buttonConfig);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState({
    name_th: null,
    name_en: null,
    created_at: null,
    updated_at: null,
  });
  // table
  const [raw, setRaw] = useState([]);
  const initState = {
    data_per_page: 5,
  };
  const [search, setSearch] = useState(initState);

  const dataHeader = [
    {
      Header: "Unit ID",
      accessor: "unit_id",
      sorted: true,
    },
    {
      Header: "House Number",
      accessor: "house_number",
    },
    {
      Header: "Room Number",
      accessor: "room_number",
    },
    {
      Header: "Floor",
      accessor: "floor",
    },
    {
      Header: "Residence  Type",
      accessor: "residence_type",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];
  useEffect(() => {
    if (!localStorage.getItem("tokenBackoffice")) {
      navigate("/login");
    }
    getDataTableBySearch();
  }, [search.page_number, search.data_per_page]);

  async function fetchDataInitialize() {
    try {
      const response = await fetchData(search);
      // เตรียมข้อมูลเพื่อแสดงในตาราง
      setSearch({
        ...search,
        total: response.data.total,
        data_per_page: response.data?.data_per_page ?? 10,
        page_number: response.data.page_number ?? 1,
      })
      setRaw({
        ...response.data,
        data: response?.data.data.map((item, index) => {
          return {
            unit_id: item.unit_id,
            house_number: item.house_number,
            floor: item.floor,
            room_number: item.room_number,
            residence_type: item.unit_id,
            status: <Status status={item.is_active} />,
            action: (
              <ButtonEdit
                path={"workspace/" + uuid1 + "/AddEditUnitManagement"}
                id={`${item.uuid}`}
              />
            ),
          };
        }),
      });
    } catch (error) { }
  }

  useEffect(() => {
    fetchDataInitialize()
  }, [])

  async function getDataTableBySearch() {
    try {
      const response = await fetchData(search);
      setRaw({
        ...response.data,
        data: response?.data.data.map((item, index) => {
          return {
            unit_id: item.unit_id,
            house_number: item.house_number,
            floor: item.floor,
            room_number: item.room_number,
            residence_type: item.unit_id,
            status: <Status status={item.is_active} />,
            action: (
              <ButtonEdit
                path={"workspace/" + uuid1 + "/AddEditUnitManagement"}
                id={`${item.uuid}`}
              />
            ),
          };
        }),
      });
    } catch (error) { }
  }



  function resetDataSearch() {
    setSearchData({
      ...searchData,
      name_th: "",
      name_en: "",
      created_at: null,
      updated_at: null,
    });

    getDataTableBySearch();
  }
  return (
    <div>
      <Header parentmenu={"Unit Management"} />
      <Menu parentmenu={"Unit Management"} activemenu={"Unit Management"} />
      <div className="content-wrapper">
        <div className="container-fluid  pdb-page">
          <HeadTitle
            title="ตารางข้อมูลยูนิต"
            status={true}
            path={"workspace/" + uuid1 + "/AddEditUnitManagement"}
            pathImport={"workspace/" + uuid1 + "/ImportDataUnitManagement"}
            pathExport={""}
          />

          <div className="box-white mt-5">
            <Card>
              <Card.Body>
                {" "}
                <Row className="">
                  <Col xs={6} md={4}>
                    <p className="fs-15 font-weight-bold mb-1">Search</p>
                    <InputData
                      type="text"
                      className="inp-data  "
                      id="account_name"
                      placeholder="Search"
                      value={searchData.name_th}
                      onChange={(e) =>
                        setSearchData({
                          ...searchData,
                          name_th: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col xs={6} md={4}>
                    <p className="fs-15 font-weight-bold mb-1">Status</p>
                    <DropdownSearch
                      placeholder={"Status"}
                      value={999}
                      onChange={(e) => {
                        setSearch({
                          ...search,
                          search: e.value,
                        });
                      }}
                      options={DataDropdownStatus}
                    />
                  </Col>{" "}
                  <Col xs={6} md={"auto"} className="align-content-end">
                    <button className="btn-search" onClick={() => { }}>
                      {/* <i className="fas fa-search"></i>  */}
                      Search
                    </button>
                  </Col>
                  <Col xs={6} md={"auto"} className="align-content-end">
                    <button
                      className="btn-reset"
                      onClick={() => resetDataSearch()}
                    >
                      {/* <i className="fas fa-undo"></i>  */}
                      Clear
                    </button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <TableContent
              columns={dataHeader}
              data={raw.data ?? []}
              total={search.total ?? 1}
              currentPage={search.page_number > 0 ? search.page_number : 1}
              dataPerPage={search.data_per_page ?? 5}
              handlerPerpage={(dataPerPage) => {
                setSearch({
                  ...search,
                  data_per_page: dataPerPage,
                })
              }
              }
              handlerSort={(eSort) => {
                console.log(eSort);
                setSearch({
                  ...search,
                  [eSort.key]: eSort.direction,
                });
              }}
              handlerChangePage={(pageNumber) => {
                setSearch({
                  ...search,
                  page_number: pageNumber,
                });
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
