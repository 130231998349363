import React, { useEffect, useState } from "react";
import Header from "../../../template/Header";
import Menu from "../../../template/Menu";
import Footer from "../../../template/Footer";
import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import HeadTitle from "../../../template/HeadTitle";
import InputData from "../../../components/Input/index";
import { DataDropdownStatus } from "../../../components/dataMockup/DataMockDropdown";
import DropdownSearch from "../../../components/DropdownSearch/DropdownSearch.jsx";
import {
  colorSaveBtn,
  hoverSaveBtn,
  colorCancleBtn,
  hoverCancleBtn,
} from "../../../components/ThemeConfig/btnConfig";
import {
  textPrimary,
  textSecond,
} from "../../../components/ThemeConfig/textConfig";
import { useTextDynamicStyles } from "../../../components/useDynamic/text_DynamicStyle";
import { useButtonDynamicStyles } from "../../../components/useDynamic/Button_DynamicStyle";
import { colorPrimary } from "../../../components/ThemeConfig/templateConfig";
import { useTemplateDynamicStyles } from "../../../components/useDynamic/template_DynamicStyle";
const buttonConfig = {
  colorSaveBtn: colorSaveBtn,
  hoverSaveBtn: hoverSaveBtn,
  colorCancleBtn: colorCancleBtn,
  hoverCancleBtn: hoverCancleBtn,
};
const templateConfig = {
  colorPrimary: colorPrimary,
};
const textConfig = {
  textPrimary: textPrimary,
  textSecond: textSecond,
};
function AddEditData() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get("id");
  useTextDynamicStyles(textConfig);
  useButtonDynamicStyles(buttonConfig);
  useTemplateDynamicStyles(templateConfig);
  const navigate = useNavigate();
  const { uuid1, uuid2, uuid3 } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useState();
  const [data, setData] = useState({
    Unit_ID: "",
    Brand: "",
    Project: "",
    HouseNumber: "",
    HousePlan: "",
    RoomNumber: "",
    FullName: "",
    Floor: "",
    Phone: "",
    Birthday: "",
    status: true,
  });

  const navigatePage = (page) => {
    navigate("/" + page);
  };

  return (
    <>
      {isLoading ? <Loading /> : ""}
      <Header parentmenu={"Resident Management"} />
      <Menu parentmenu={"Resident"} activemenu={"Resident"} />
      <div className="content-wrapper">
        <div className="container-fluid  pdb-page">
          <HeadTitle
            title="รายละเอียดข้อมูลลูกบ้าน"
            status={false}
            path={""}
            pathImport={""}
            pathExport={""}
          />
          <div className="box-white mt-4">
            <div className="px-3">
              <p className="text-main fs-17 font-weight-bold">ข้อมูลยูนิต</p>
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Unit ID</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Unit_ID"
                        placeholder="Unit ID"
                        value={data.Unit_ID}
                        onChange={(event) =>
                          setData({
                            ...data,
                            Unit_ID: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">
                        Residence Type&nbsp;
                        <>
                          <span className="text-danger">*</span>
                        </>
                      </span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <DropdownSearch
                        placeholder={"Status"}
                        value={999}
                        onChange={(e) => {
                          setSearch({
                            ...search,
                            search: e.value,
                          });
                        }}
                        options={DataDropdownStatus}
                      />
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Brand</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Brand"
                        placeholder="Brand"
                        value={data.Brand}
                        onChange={(event) =>
                          setData({
                            ...data,
                            Brand: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Project</span>
                      &nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Project"
                        placeholder="Project"
                        value={data.Project}
                        onChange={(event) =>
                          setData({
                            ...data,
                            Project: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">House Number</span>
                      &nbsp;<span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="House Number"
                        placeholder="House Number"
                        value={data.HouseNumber}
                        onChange={(event) =>
                          setData({
                            ...data,
                            HouseNumber: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">House Plan</span>
                      &nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="HousePlan"
                        placeholder="HousePlan"
                        value={data.HousePlan}
                        onChange={(event) =>
                          setData({
                            ...data,
                            HousePlan: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Room Number</span>
                      &nbsp;<span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Room Number"
                        placeholder="Room Number"
                        value={data.RoomNumber}
                        onChange={(event) =>
                          setData({
                            ...data,
                            RoomNumber: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Floor</span>
                      &nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Floor"
                        placeholder="Floor"
                        value={data.Floor}
                        onChange={(event) =>
                          setData({
                            ...data,
                            Floor: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>{" "}
              <p className="text-main fs-17 mt-3 font-weight-bold">
                ข้อมูลลูกบ้าน
              </p>
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Full Name</span>
                      &nbsp;<span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Full Name"
                        placeholder="Full Name"
                        value={data.FullName}
                        onChange={(event) =>
                          setData({
                            ...data,
                            FullName: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Phone</span>
                      &nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Phone"
                        placeholder="Phone"
                        value={data.Phone}
                        onChange={(event) =>
                          setData({
                            ...data,
                            Phone: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Birthday</span>
                      &nbsp;<span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Birthday"
                        placeholder="Birthday"
                        value={data.Birthday}
                        onChange={(event) =>
                          setData({
                            ...data,
                            Birthday: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6} className="align-content-center">
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Last Login</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <span className="fs-15">2024-09-25 10:00</span>
                    </Col>
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              {/* {editId && (
                <> */}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Create at</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <span className="fs-15">2024-09-25 10:00</span>
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Create by</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <span className="fs-15">Pichtira Siriwarin</span>
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Update at</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <span className="fs-15">2024-09-25 10:00</span>
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Update by</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <span className="fs-15">Pichtira Siriwarin</span>
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>{" "}
              {/* </>
              )} */}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Status</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <Form>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`}>
                            <Form.Check
                              className={
                                data.status === true
                                  ? "fs-15 radio-item checked"
                                  : "fs-15 radio-item"
                              }
                              inline
                              label="Active"
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                              checked={data.status}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  status: true,
                                })
                              }
                            />
                            <Form.Check
                              className={
                                data.status === false
                                  ? "fs-15 radio-item checked"
                                  : "fs-15 radio-item "
                              }
                              inline
                              label="Inactive"
                              name="group1"
                              type={type}
                              id={`inline-${type}-2`}
                              checked={!data.status}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  status: false,
                                })
                              }
                            />
                          </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>
            </div>
            <Row className="d-flex mt-4">
              <Col xs={6} md={"auto"} className="d-flex justify-content-start ">
                <button
                  className={"btn-cancle text-white"}
                  onClick={(e) => {
                    navigatePage("/workspace/" + uuid1 + "/masterDataResident");
                  }}
                >
                  Back
                </button>{" "}
                <button
                  className="btn-save"
                  onClick={(e) => {
                    // handleSubmit();
                  }}
                >
                  Save
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddEditData;
