import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../template/Header";
import Menu from "../../../template/Menu";
import Footer from "../../../template/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loading from "../../../components/loading/Loading";
import {
  dataVerify,
  dataImport,
} from "../../../api/residenceTypeRoutes/index.js";
import HeadTitle from "../../../template/HeadTitle";
import { Link, useNavigate } from "react-router-dom";
import ImportExcel from "../../../components/uploads/ExcelRender";
import {
  colorSaveBtn,
  hoverSaveBtn,
  colorCancleBtn,
  hoverCancleBtn,
  colorUploadBtn,
  hoverUploadBtn,
  colorVerifyBtn,
  hoverVerifyBtn,
} from "../../../components/ThemeConfig/btnConfig";
import { useButtonDynamicStyles } from "../../../components/useDynamic/Button_DynamicStyle";
import {
  colorPrimary,
  colorPrimaryhover,
} from "../../../components/ThemeConfig/templateConfig";
import { useTemplateDynamicStyles } from "../../../components/useDynamic/template_DynamicStyle";
import TableContent from "../../../components/TableContent/TableContent.jsx";
import CustomSweetAlert from "../../../components/Sweetalert/index";
const buttonConfig = {
  colorSaveBtn: colorSaveBtn,
  hoverSaveBtn: hoverSaveBtn,
  colorCancleBtn: colorCancleBtn,
  hoverCancleBtn: hoverCancleBtn,
  colorUploadBtn: colorUploadBtn,
  hoverUploadBtn: hoverUploadBtn,
  colorVerifyBtn: colorVerifyBtn,
  hoverVerifyBtn: hoverVerifyBtn,
};
const templateConfig = {
  colorPrimary: colorPrimary,
  colorPrimaryhover: colorPrimaryhover,
};

function ImportData() {
  useButtonDynamicStyles(buttonConfig);
  useTemplateDynamicStyles(templateConfig);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const navigateBack = (data) => {
    navigate("/" + data);
  };
  // import data ---
  //
  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [verifyNew, setVerifyNew] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState("verify");
  const [failedRow, setFailedRow] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [emptyCell, setEmptyCell] = useState(0);
  const [statusVerify, setStatusVerify] = useState(true);
  const [raw, setRaw] = useState([]);
  const [responCode, setResponCode] = useState(null);
  const initState = {
    data_per_page: 5,
  };
  const [search, setSearch] = useState(initState);

  const dataHeader = [
    {
      Header: "In Row",
      accessor: "no",
    },
    {
      Header: "Error Message",
      accessor: "errorMessage",
    },
  ];
  const handleSubmit = async () => {
    // setShowAlert(status === "successverify" ? false : true);
    setShowAlert(true);
  };

  const handleConfirm = async (statusData) => {
    setShowAlert(false); // Hide the alert after confirmation
    if (statusData === "Confirmed" && status === "successverify") {
      setStatus("create");
    } else if (statusData === "Close") {
      navigateBack("masterDataResidence");
    } else {
      try {
        // setShowAlert(true);
        // setStatus("create");
        setVerifySuccess(0);
        setVerifySkip(0);
        setVerifyNew(0);
        setFailedRow([]);
        setTotalRow(importData.length);

        if (statusData === "Confirmed" && responCode) {
          setStatus("create");
          setResponCode(null);
        } else if (statusData === "Confirmed" && status === "errorverify") {
          setStatus("create");
          setResponCode(null);
        } else {
          await setLoading(true);
          let updatedData = importData.map(({ key, ...rest }) => rest);
          let result = {
            importData: updatedData,
          };
          const response = await dataImport(result);

          await setLoading(false);
          if (response && response.data.code === "20000") {
            // Successful response handling
            setStatus("success");
            setShowAlert(true);

            // Wait for user to click "Close" before navigating
          } else {
            setStatus("error");
            setShowAlert(true);
            setResponCode(response.status);
          }
        }
      } catch (error) {}
    }
  };

  // Function to handle cancellation
  const handleCancel = (status) => {
    setShowAlert(false); // Hide the alert after cancellation
  };
  const handleImport = async (rows) => {
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);

    const row = [];

    rows.slice(1).map((item, index) => {
      if (item.length > 0) {
        row.push({
          key: item[0],
          residence_en: item[1],
          residence_th: item[2],
          is_active: item[3],
        });
      }
    });

    await setImportData(row);
    // setTotalRow(rows.length - 1);
    setTotalRow(row.length);
    let emptyRows = row
      .map((item, index) => {
        let emptyCells = {};

        ["residence_en", "residence_th", "is_active"].forEach((column) => {
          if (item[column] === undefined) {
            // Mark the column as having an empty cell and add custom text properties
            emptyCells[column] = true;

            // Add additional properties as needed
            if (column === "residence_en") {
              emptyCells["text_en"] = "ชื่อประเภทที่อยู่อาศัย (EN)";
            }
            if (column === "is_active") {
              emptyCells["text_status"] = "สถานะใช้งาน";
            }
          }
        });

        // Return an object with rowIndex and emptyCells if any empty cells are found
        return Object.keys(emptyCells).length > 0
          ? { rowIndex: index, emptyCells: emptyCells }
          : null;
      })
      .filter((entry) => entry !== null);

    // setEmptyCell(emptyCells.length);
    if (emptyRows.length > 0) {
      // setShowAlert(true);
      setStatusVerify(true);
      setEmptyCell(emptyRows.length);
      setRaw({
        ...emptyRows,
        data: emptyRows.map((item, index) => {
          return {
            no: item.rowIndex,
            errorMessage: Object.keys(item.emptyCells)
              .map((key) => {
                // Construct the custom error message with associated text if available
                if (key === "residence_en" && item.emptyCells.text_en) {
                  return `${key} (${item.emptyCells.text_en}) is empty`;
                } else if (key === "residence_th" && item.emptyCells.text_th) {
                  return `${key} (${item.emptyCells.text_th}) is empty`;
                } else if (key === "is_active" && item.emptyCells.text_status) {
                  return `${key} (${item.emptyCells.text_status}) is empty`;
                }
              })
              .filter(Boolean) // Ensure no undefined or empty strings are included
              .join(", "), // Join the array of strings into a single string
          };
        }),
      });
    } else {
      setStatusVerify(false);
    }
    const duplicates = findDuplicates(row);

    if (duplicates.length > 0) {
      setStatusVerify(true); // Mark verification as needed
      setEmptyCell(duplicates.length); // Update the count of duplicates

      // Update state with detailed error messages
      setRaw({
        ...duplicates,
        data: duplicates.map((item) => ({
          no: item.key, // Use the `key` field for identification
          errorMessage: Object.keys(item)
            .map(
              (fieldKey) => generateErrorMessage(fieldKey, item) // Generate message per field
            )
            .filter(Boolean) // Remove null/undefined messages
            .join(", "), // Combine all messages into one string
        })),
      });
    }
  };
  const generateErrorMessage = (key, item) => {
    const { residence_en, residence_th, is_active } = item; // Extract relevant fields

    switch (key) {
      case "residence_en":
        return `${key} is ข้อมูลซ้ำ`; // "ข้อมูลซ้ำ" = Duplicate
      case "residence_th":
        return `${key} is ข้อมูลซ้ำ`;
      case "is_active":
        return `${key} is ข้อมูลซ้ำ`;
      default:
        return null; // Ignore keys that do not need messages
    }
  };
  const findDuplicates = (data) => {
    const seen = new Map();
    const duplicates = [];

    data.forEach((item) => {
      // Create a unique identifier string for each object
      const uniqueKey = `${item.residence_en}-${item.residence_th}-${item.is_active}`;

      if (seen.has(uniqueKey)) {
        duplicates.push(item);
      } else {
        seen.set(uniqueKey, true);
      }
    });

    return duplicates;
  };
  const verifyData = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      let create = 0;
      let skip = 0;
      let error = 0;
      const batchSize = 10;
      const failedArr = [];

      await setLoading(true);
      const response = await dataVerify(importData);
      await setLoading(false);

      if (response && response.code === "20000") {
        setShowAlert(true);
        setStatus("success");
        create += response.create;
        setTotalRow(response.total);
        const failedData = response.resultFail;
        failedData.length > 0 ? setIsVerify(true) : setIsVerify(false);
        setEmptyCell(failedData.length);
        setRaw({
          ...failedData,
          data: failedData.map((item, index) => ({
            no: index + 1, // Use the `key` field for identification
            errorMessage: item,
          })),
        });

        setVerifySuccess(response.total);
        setVerifySkip(skip);
        setVerifyNew(create);
        setFailedRow(failedArr);

        if (failedArr.length < 1) {
          setStatus("successverify");
        }
      } else {
        error++;
        setStatus("errorverify");
        setShowAlert(true);

        return;
      }
    } catch (error) {}
  };
  return (
    <>
      <>
        {isLoading ? <Loading /> : ""}
        {showAlert && (
          <CustomSweetAlert
            data={{
              showCancel:
                status === "success" ||
                status === "error" ||
                status === "successverify" ||
                status === "errorverify"
                  ? false
                  : true,
              reverseButtons: true,
              confirmBtnText:
                {
                  create: "ใช่",
                  edit: "ใช่",
                  success: "ตกลง",
                }[status] || status === "successverify"
                  ? "verify"
                  : "ตกลง",
              cancelBtnText: "ไม่",
              icon: "",
              title:
                {
                  create: "WARNING",
                  edit: "WARNING",
                  success: "SUCCESS",
                  successverify: "SUCCESS",
                }[status] || "ERROR",
              message:
                {
                  create: t("popup.confirm"),
                  edit: t("popup.confirm"),
                  success: t("popup.success"),
                  successverify: t("popup.verify"),
                }[status] || t("popup.failed"),
              onConfirm: handleConfirm,
              onCancel: handleCancel,
              colorBorder: "#164082",
              colorBtnConfirm: "#164082",
              colorBtnCancel: "#FFFFFF",
            }}
          />
        )}
        <Header parentmenu={"Master Data"} />
        <Menu parentmenu={"MasterData"} activemenu={"RepairType"} />
        <div className="content-wrapper">
          <div className="container-fluid  pdb-page">
            <HeadTitle
              title="ประเภทการแจ้งซ่อม"
              status={false}
              path={""}
              pathImport={""}
              pathExport={""}
            />
            <div className="box-white mt-4">
              {" "}
              <font className="font-weight-bold">
                <span className="m-0 fs-15 font-weight-bold ">
                  เพิ่มข้อมูลทีละหลายรายการ
                </span>
              </font>
              <div className="row justify-content-start align-items-center  mt-1">
                <div className="col-auto">
                  <p className="fs-15 font-weight-bold  mb-0">
                    กรุณา Download Template Form ตามแนบ สำหรับ Upload File
                  </p>
                </div>
                <div className="col-auto mt-2 mt-md-0">
                  <Link
                    as={Link}
                    to={`${process.env.REACT_APP_BASE_URL}/testdrive/template/instructor-schedule`}
                    target="_blank"
                    className="btn-download text-white d-inline-block h-auto"
                    id="downloadBtn"
                    value="download"
                  >
                    Download Template Form
                  </Link>
                </div>
              </div>
              <div className="row justify-content-start  pt-3">
                <div className="col-12 mb-2 mb-lg-2">
                  <p className=" font-weight-bold ">
                    Upload File
                    <>
                      {" "}
                      <span className="mx-2">
                        {verifySuccess}/{totalRow}
                      </span>
                    </>
                  </p>
                  <span className="text-black mb-1 fs-13">
                    รอบรับไฟล์ .xlsx
                  </span>
                </div>
                <div className="col-12 col-lg-8 pr-2 pr-lg-0 mt-3 mt-lg-0">
                  <ImportExcel onImport={handleImport} className="" />
                </div>
                <div className="col-12 col-lg-3">
                  <button
                    className="btn-verify "
                    disabled={statusVerify}
                    onClick={verifyData}
                    // onClick={(e) => {
                    //   handleSubmit();
                    // }}
                  >
                    ตรวจสอบไฟล์
                  </button>
                </div>
              </div>{" "}
              {emptyCell > 0 ? (
                <>
                  <div className="col-12 mt-3">
                    <p className="text-danger font-weight-bold">
                      Error Message :{" "}
                    </p>
                  </div>
                  {/*   failedRow.length > 0 เอาไว้เช้ค error import file */}
                  <TableContent
                    bgHeader={"#DB000E"}
                    columns={dataHeader}
                    data={raw.data || []}
                    total={raw.total || 0}
                    currentPage={raw.page_number || 0}
                    dataPerPage={raw.data_per_page}
                    handlerPerpage={(dataPerPage) =>
                      setSearch({
                        ...search,
                        data_per_page: dataPerPage,
                      })
                    }
                    handlerSort={(eSort) => {
                      console.log(eSort);
                      setSearch({
                        ...search,
                        [eSort.key]: eSort.direction,
                      });
                    }}
                    handlerChangePage={(pageNumber) => {
                      setSearch({
                        ...search,
                        page_number: pageNumber,
                      });
                    }}
                  />
                </>
              ) : (
                <></>
              )}
              <Row className="d-flex mt-5">
                <Col xs={6} className="d-flex justify-content-start">
                  <button
                    className="btn-cancle ml-2 text-white  "
                    onClick={(e) => {
                      navigateBack("masterDataResidence");
                    }}
                  >
                    Back
                  </button>{" "}
                  <button
                    className="btn-save ml-3"
                    disabled={isVerify}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <Footer />
      </>
    </>
  );
}

export default ImportData;
