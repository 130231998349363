import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
const DateDisplay = ({ date }) => {
  // Check if the date exists, then format it
  const formattedDate = date ? moment(date).format("YYYY-MM-DD HH:mm ") : "-";

  return <div>{formattedDate}</div>;
};
DateDisplay.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date), // Accepts a Date object
    PropTypes.string, // Accepts a string representation of a date
  ]),
};
export default DateDisplay;
