import React, { createContext, useEffect } from "react"
import { useState } from 'react'
import iconsuccess from "src/components/Sweetalert/icon-img/ic-success.png";
import iconwarning from "src/components/Sweetalert/icon-img/ic-warning.png";
import iconerror from "src/components/Sweetalert/icon-img/ic-error.png";
import "./styles/contextPopup.css"
import Swal from "sweetalert2";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next"

export const PopupContext = createContext()

const PopupContextProvider = ({ children }) => {
    const { t } = useTranslation()

    const colorMap = {
        warning: "#F7BC0B",
        success: "#28CC7D",
        delete: "#E32A29",
        error: "#E32A29",
    }
    const [popup, setPopup] = useState({
        status: 'warning',
        confirmBtnText: 'Close',
        cancelBtnText: 'Cancel',
        showCancel: false,
        colorBtnCancel: '#E0E0E0',
        onConfirm: () => { }
    })

    const mappingTextPopup = {
        error: t('popup.failed'),
        success: t('popup.success'),
        warning: t('popup.confirm'),
    }

    const mappingIconPopup = {
        warning: iconwarning,
        error: iconerror,
        success: iconsuccess,
    }
    const currentStatus = popup?.status?.toLowerCase()
    useEffect(() => {
        if (popup?.show) {
            Swal.fire({
                title: '',
                text: popup.title ?? mappingTextPopup[`${currentStatus}`],
                imageUrl: mappingIconPopup[`${currentStatus}`],
                imageWidth: 80,
                imageHeight: 80,
                icon: popup?.icon,
                showCancelButton: popup?.showCancel,
                confirmButtonText: popup?.confirmBtnText ?? t('confirm'),
                cancelButtonText: popup?.cancelBtnText ?? t('not'),
                confirmButtonColor: '#164082',
                cancelButtonColor: '#00000000',
                reverseButtons: popup?.reverseButtons,
                customClass: {
                    popup: "my-swal-popup",
                },
                didOpen: () => {
                    // Set custom color for the popup
                    document
                        .querySelector(".my-swal-popup")
                        ?.style.setProperty("--custom-color", popup.colorBorder);
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    popup.onConfirm(
                        popup.confirmBtnText === "Close" ? "Close" : "Confirmed"
                    );
                } else if (result.isDismissed) {
                    popup.onCancel("Cancelled");
                }
            });
        }
    }, [popup]);

    return (
        <PopupContext.Provider value={
            { popup, setPopup } // nosonar
        }>
            {children}
        </PopupContext.Provider>
    )
}


PopupContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PopupContextProvider