import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../template/Header";
import Menu from "../../../template/Menu";
import Footer from "../../../template/Footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import HeadTitle from "../../../template/HeadTitle";
import InputData from "../../../components/Input/index";
import {
  colorSaveBtn,
  hoverSaveBtn,
  colorCancleBtn,
  hoverCancleBtn,
} from "../../../components/ThemeConfig/btnConfig";
import { useButtonDynamicStyles } from "../../../components/useDynamic/Button_DynamicStyle";
import { colorPrimary } from "../../../components/ThemeConfig/templateConfig";
import { useTemplateDynamicStyles } from "../../../components/useDynamic/template_DynamicStyle";
import CustomSweetAlert from "../../../components/Sweetalert/index";
import DateDisplay from "../../../components/momentDateTime/index";
import {
  create,
  getListById,
  updateById,
} from "../../../api/ResidenceRoutes/index.js";
import { faL } from "@fortawesome/free-solid-svg-icons";
const buttonConfig = {
  colorSaveBtn: colorSaveBtn,
  hoverSaveBtn: hoverSaveBtn,
  colorCancleBtn: colorCancleBtn,
  hoverCancleBtn: hoverCancleBtn,
};
const templateConfig = {
  colorPrimary: colorPrimary,
};
function AddEditData() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get("id");

  useButtonDynamicStyles(buttonConfig);
  useTemplateDynamicStyles(templateConfig);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState("create");
  const [responCode, setResponCode] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [data, setData] = useState({
    Residence_ID: "",
    residence_en: null,
    residence_th: null,
    is_active: true,
  });
  const [error, setError] = useState({
    error_EN: false,
    error_TH: false,
  });
  

  const navigatePage = (page) => {
    navigate("/" + page);
  };
  const inputSetData = (value, language) => {
    const englishOnly = value.replace(/[^A-Za-z0-9\s]/g, "");
    const thaiOnly = value.replace(/[^ก-๙0-9\s]/g, "");
    if (language === "en") {
      setData((prevData) => ({
        ...prevData,
        residence_en: englishOnly,
      }));
    } else {
      setData({
        ...data,
        residence_th: thaiOnly,
      });
    }
    // error_EN: language === "en" && englishOnly === "" ? true : false,
    // error_TH: language !== "en" && thaiOnly === "" ? true : false,
    setError((prevError) => {
      let error_EN;
      let error_TH;

      if (language === "en") {
        error_EN = englishOnly === "";
      } else if (thaiOnly) {
        error_EN = false;
      } else {
        error_EN = prevError.error_EN;
      }

      if (language !== "en") {
        error_TH = thaiOnly === "";
      } else if (englishOnly) {
        error_TH = false;
      } else {
        error_TH = prevError.error_TH;
      }

      return {
        ...prevError,
        error_EN: error_EN,
        error_TH: error_TH,
      };
    });
  };
  const handleSubmit = () => {
    setShowAlert(true); // Show the alert when this function is called
  };

  const handleConfirm = async (statusData) => {
   
    setShowAlert(false); // Hide the alert after confirmation
    let dataObject = {
      is_active: data.is_active,
      residence_en: data.residence_en,
      residence_th: data.residence_th,
    };

    try {
      if (statusData === "Close") {
        navigatePage("masterDataResidence");
      } else if (statusData === "Confirmed" && responCode) {
        setStatus("create");
        setResponCode(null);
      } else {
        const response = editId
          ? await updateById(editId, dataObject)
          : await create(dataObject);
        
        if (
          (response && response.code === "20000") ||
          response.data.code === "20000"
        ) {
          // Successful response handling
          setStatus("success");
          setShowAlert(true);

          // Wait for user to click "Close" before navigating
        } else {
          setStatus("error");
          setShowAlert(true);
          setResponCode(response.status);
        }
      }
    } catch (error) {}
  };

  // Function to handle cancellation
  const handleCancel = (status) => {
 
    setShowAlert(false); // Hide the alert after cancellation
    if (!editId) {
      // setData({
      //   Residence_ID: "",
      //   residence_en: null,
      //   residence_th: null,
      //   status: true,
      // });
      setError({
        error_EN: false,
        error_TH: false,
      });
    }
  };
  const getDataByID = async () => {
    try {
      const response = await getListById({ uuid: editId });

      // let response = 0;
      if (response) {
        setStatus("edit");

        setData({
          ...data,
          Residence_ID: response.data.data.residence_id || null,
          residence_en: response.data.data.residence_en || null,
          residence_th: response.data.data.residence_th || null,
          is_active: response.data.data.is_active ? true : false,
          created_at: response.data.data.created_at || "-",
          created_by: response.data.data.created_by || "-",
          updated_at: response.data.data.updated_at || "-",
          updated_by: response.data.data.updated_by || "-",
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (editId) {
      getDataByID();
    }
  }, []);
  return (
    <>
      {isLoading ? <Loading /> : ""}{" "}
      {showAlert && (
        <CustomSweetAlert
          data={{
            showCancel:
              status === "success" || status === "error" ? false : true,
            reverseButtons: true,
            confirmBtnText:
              {
                create: "ใช่",
                edit: "ใช่",
                success: "ตกลง",
              }[status] || "ตกลง",
            cancelBtnText: "ไม่",
            icon: "",
            title:
              {
                create: "WARNING",
                edit: "WARNING",
                success: "SUCCESS",
              }[status] || "ERROR",
            message:
              {
                create: t("popup.confirm"),
                edit: t("popup.confirm"),
                success: t("popup.success"),
              }[status] || t("popup.failed"),
            onConfirm: handleConfirm,
            onCancel: handleCancel,
            colorBorder: "#164082",
            colorBtnConfirm: "#164082",
            colorBtnCancel: "#FFFFFF",
          }}
        />
      )}
      <Header parentmenu={"Master Data"} />
      <Menu parentmenu={"MasterData"} activemenu={"Residence"} />
      <div className="content-wrapper">
        <div className="container-fluid  pdb-page">
          <HeadTitle
            title="รายละเอียดประเภทที่อยู่อาศัย"
            status={false}
            path={""}
            pathImport={""}
            pathExport={""}
          />
          <div className="box-white mt-4">
            <div className="px-3">
              {" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Residence Type ID</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Residence_ID"
                        placeholder="Residence Type ID"
                        value={data.Residence_ID}
                        onChange={(event) =>
                          setData({
                            ...data,
                            Residence_ID: event.target.value,
                          })
                        }
                        // disable={editId ? false : true}
                        disable={true}
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Residence Type Name (EN)</span>
                      &nbsp;<span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="residence_en"
                        placeholder="Residence Type Name (EN)"
                        value={data.residence_en || ""}
                        onChange={(event) => {
                          inputSetData(event.target.value, "en");
                        }}
                        // disable={data.residence_th ? true : false}
                      />{" "}
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Residence Type Name (TH)</span>
                      &nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="residence_th"
                        placeholder="Residence Type Name (TH)"
                        value={data.residence_th || ""}
                        onChange={(event) => {
                          inputSetData(event.target.value, "th");
                        }}
                        // disable={data.residence_en ? true : false}
                      />
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>{" "}
              <Row>
                <Col md={6} xl={6}>
                  <Row>
                    <Col
                      xs={4}
                      md={5}
                      xl={5}
                      className="align-self-center"
                    ></Col>
                    <Col xs={8} md={7} xl={7}>
                      <p
                        className={
                          error.error_EN
                            ? "fs-14 mt-1 text-danger font-weight-bold"
                            : "d-none"
                        }
                      >
                        {" "}
                        {t("master_Residence.residence_en")}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} xl={6}>
                  {" "}
                  <Row>
                    <Col
                      xs={4}
                      md={5}
                      xl={5}
                      className="align-self-center"
                    ></Col>
                    <Col xs={8} md={7} xl={7}>
                      <p
                        className={
                          error.error_TH
                            ? "fs-14 mt-1 text-danger font-weight-bold"
                            : "d-none"
                        }
                      >
                        {" "}
                        {t("master_Residence.residence_th")}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {editId && (
                <>
                  <Row className="mt-3">
                    <Col md={6} xl={6}>
                      <Row>
                        <Col xs={4} md={5} xl={5} className="align-self-center">
                          <span className="fs-15">Create at</span>
                        </Col>
                        <Col xs={8} md={7} xl={7}>
                          <span className="fs-15">
                            <DateDisplay date={data?.created_at} />
                          </span>
                        </Col>
                      </Row>{" "}
                    </Col>{" "}
                    <Col md={6} xl={6}>
                      <Row>
                        <Col xs={4} md={5} xl={5} className="align-self-center">
                          <span className="fs-15">Create by</span>
                        </Col>
                        <Col xs={8} md={7} xl={7}>
                          <span className="fs-15">{data?.created_by}</span>
                        </Col>
                      </Row>{" "}
                    </Col>
                  </Row>{" "}
                  <Row className="mt-3">
                    <Col md={6} xl={6}>
                      <Row>
                        <Col xs={4} md={5} xl={5} className="align-self-center">
                          <span className="fs-15">Update at</span>
                        </Col>
                        <Col xs={8} md={7} xl={7}>
                          <span className="fs-15">
                            {" "}
                            <DateDisplay date={data?.updated_at} />
                          </span>
                        </Col>
                      </Row>{" "}
                    </Col>{" "}
                    <Col md={6} xl={6}>
                      <Row>
                        <Col xs={4} md={5} xl={5} className="align-self-center">
                          <span className="fs-15">Update by</span>
                        </Col>
                        <Col xs={8} md={7} xl={7}>
                          <span className="fs-15">{data?.updated_by}</span>
                        </Col>
                      </Row>{" "}
                    </Col>
                  </Row>{" "}
                </>
              )}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Status</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <Form>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`}>
                            <Form.Check
                              className={
                                data.is_active === true
                                  ? "fs-15 radio-item checked"
                                  : "fs-15 radio-item"
                              }
                              inline
                              label="Active"
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                              checked={data.is_active}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  is_active: true,
                                })
                              }
                            />
                            <Form.Check
                              className={
                                data.is_active === false
                                  ? "fs-15 radio-item checked"
                                  : "fs-15 radio-item "
                              }
                              inline
                              label="Inactive"
                              name="group1"
                              type={type}
                              id={`inline-${type}-2`}
                              checked={!data.is_active}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  is_active: false,
                                })
                              }
                            />
                          </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>
            </div>
            <Row className="d-flex mt-4">
              <Col xs={6} md={"auto"} className="d-flex justify-content-start ">
                <button
                  className={"btn-cancle text-white"}
                  onClick={(e) => {
                    navigatePage("masterDataResidence");
                  }}
                >
                  Back
                </button>{" "}
                {/* error.error_EN && error.error_TH */}
                <button
                  disabled={
                    !data.residence_en ||
                    !data.residence_th ||
                    data.residence_en === "" ||
                    data.residence_th === ""
                  }
                  className="btn-save"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  Save
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddEditData;
