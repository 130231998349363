import { DELETE, GET, POST, PUT } from "../baseapi";

const routes = {
  verify_import: "/api/admin/brand/verify-import",
  data_import: "/api/admin/brand/data-import",
  root_path: "/api/admin/brand",
};
export const dataVerify = async (importData) => {
  const url = routes.verify_import;
  const jsonData = {
    importData: importData,
  };
  const result = await POST(url, jsonData);

  return result;
};

export const dataImport = async (data) => {
  const url = routes.data_import;
  const jsonData = {
    importData: data,
  };
  const result = await POST(url, data);

  return result;
};
export const getLists = async (query) => {
  const url = routes.root_path;
  const result = GET(url, query || {});

  return result;
};
export const create = async (query) => {
  console.log("query : ", query);
  const formDataObject = new FormData();
  formDataObject.append("is_active", query.is_active);
  formDataObject.append("brand_en", query.brand_en);
  formDataObject.append("brand_th", query.brand_th);
  formDataObject.append("website_url", query.website_url || "");
  formDataObject.append("file", query.file[0].file);
  const url = routes.root_path;
  const result = await POST(url, formDataObject, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return result;
};

export const getListById = async (query) => {
  const url = routes.root_path + "/" + query?.uuid;
  const result = GET(url, {});
  return result;
};
export const updateById = async (id, query) => {
  console.log("query update  : ", query);
  const formDataObject = new FormData();
  formDataObject.append("is_active", query.is_active);
  formDataObject.append("brand_en", query.brand_en);
  formDataObject.append("brand_th", query.brand_th);
  formDataObject.append("website_url", query.website_url || "");
  formDataObject.append("file", query.file[0].file);
  const url = routes.root_path + "/" + id;
  const result = await PUT(url, formDataObject, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return result;
};
export const removeById = async (uuid) => {
  const url = routes.root_path + "/" + uuid;
  const result = await DELETE(url);
  return result;
};
