import React from "react";
import "./Thumbnail.css";
import iconDelete from "../../assets/icons/ic-add.png";
import PropTypes from "prop-types"; // Import PropTypes for validation
function Thumbnail(props) {
  const { file, removeFile } = props;

  return (
    <div className="thumb">
      <div className="thumbInner">
        <img src={file.src} className="img ml-3 img-w" alt="" />
      </div>
      <button className={"remove-img"} onClick={() => removeFile(file.id)}>
        <img
          src={iconDelete}
          alt="ExportFiles Icon"
          className="ic-remove-img"
        />
      </button>
    </div>
  );
}
Thumbnail.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // id can be string or number
    src: PropTypes.string.isRequired, // src must be a string and is required
  }).isRequired,
  removeFile: PropTypes.func.isRequired, // removeFile must be a function and is required
};
export default Thumbnail;
