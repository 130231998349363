import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  headPrimaryColor,
  headSecondColor,
  mainHeaderbg,
  headColor,
} from "../components/ThemeConfig/headerConfig";
import { useHeaderDynamicStyles } from "../components/useDynamic/header_DynamicStyle";
import { fetchProfileDataList } from "../api/User_api";
import iconUser from "../assets/icon-header/ic-user.png";
import { setTimeOutSync } from "../utils/setTimeout";
import PropTypes from "prop-types"; // Import PropTypes
const headConfig = {
  headPrimaryColor: headPrimaryColor,
  headSecondColor: headSecondColor,
  mainHeaderbg: mainHeaderbg,
  headColor: headColor,
};
function Header({ parentmenu }) {
  useHeaderDynamicStyles(headConfig);
  const [isLoading, setLoading] = useState(false);
  const [dataProfile, setDataProfile] = useState({
    name: null,
    permission: null,
  });
  const navigate = useNavigate();

  const logout = () => {
    sessionStorage.removeItem("tokenBackoffice");
    localStorage.removeItem("tokenBackoffice");
    localStorage.removeItem("UserData");
    navigate("/login");
  };
  async function getDataProfile() {
    try {
      setLoading(true);
      setTimeOutSync(() => setLoading(false)).then(async () => {
        let response = await fetchProfileDataList();
        setDataProfile((prevProfile) => ({
          ...prevProfile, // Spread the existing state to preserve other fields
          name: response.firstname + " " + response.lastname,
          permission: response.role_name, // Assuming permission is based on the last name
        }));
      });
    } catch (error) {}
  }
  useEffect(() => {
    // getDataProfile();
  }, []);
  return (
    <nav className="main-header navbar navbar-expand navbar-Template navbar-light">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link
            as={Link}
            className="nav-link"
            data-widget="pushmenu"
            role="button"
            // onClick={handleClickPushMenu}
          >
            <i className="fas fa-bars text-white" />
          </Link>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <p className="nav-link  text-white d-flex align-items-center m-0">
            {parentmenu}
          </p>
        </li>
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        {/* Profile Dropdown Menu */}
        <li className="nav-item nav-profile">
          <div className="">
            {/* <button
              className="nav-link count-indicator  btn  dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            > */}
            {/* <li className="nav-item d-flex align-items-center"> */}{" "}
            <div className="row">
              <div className="col-3 align-content-center">
                <img
                  className=" px-1 ml-2"
                  src={iconUser}
                  width="45px"
                  alt=""
                />
              </div>
              <div className="col-9 align-self-center text-start pl-3">
                <p className=" font-weight-bold mb-0 text-white ">
                  {dataProfile?.name}
                </p>
                <p className=" text-sm text-white mb-0">
                  {dataProfile?.permission}
                </p>
              </div>
            </div>
            {/* </li> */}
            {/* </button>{" "} */}
            {/* <div
              className="dropdown-menu dropdown-menu-right  line-height03"
              aria-labelledby="dropdownMenuButton"
            >
              <button onClick={() => logout()} className="dropdown-item">
                ลงชื่อออก
              </button>
            </div> */}
          </div>
        </li>
      </ul>
    </nav>
  );
}
Header.propTypes = {
  parentmenu: PropTypes.string.isRequired, // Ensuring parentmenu is a required string
};
export default Header;
