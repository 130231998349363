import Joi from 'joi';

export const schema = Joi.object({
    password: Joi.string()
        .min(8) // Minimum 8 characters
        .pattern(/[\W_]/) // Must contain at least one special character
        .pattern(/\d/) // Must contain at least one number
        .required()
        .messages({
            'string.min': 'รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร',
            'string.pattern.base': 'รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร และต้องมีตัวอักษรพิเศษหนึ่งตัวขึ้นไป',
            'string.empty': 'รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร และต้องมีตัวอักษรพิเศษหนึ่งตัวขึ้นไป.',
        }),

    newpassword: Joi.string()
        .valid(Joi.ref('password')) // Must match the password field
        .required()
        .messages({
            'any.only': 'รหัสผ่านใหม่และยืนยันรหัสผ่านไม่ตรงกัน กรุณาตรวจสอบและลองใหม่',
            'string.empty': 'รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร และต้องมีตัวอักษรพิเศษหนึ่งตัวขึ้นไป',
        }),
});
