import { DELETE, GET, POST, PUT } from "../baseapi"

const routes = {
    root_path: '/api/admin/unit',
}

export const getLists = async () => {
    const url = routes.root_path
    const result = await GET(url)
    return result
}
export const create = async (
    residence_id, project_id, house_number, house_plan, building, room_number, floor, is_active
) => {
    const url = routes.root_path
    const jsonData = {
        residence_id: residence_id,
        project_id: project_id,
        house_number: house_number,
        house_plan: house_plan,
        building: building,
        room_number: room_number,
        floor: floor,
        is_active: is_active,
    };
    const result = await POST(url, jsonData)
    return result
}
export const getListById = async (uuid) => {
    const url = routes.root_path + '/' + uuid
    const result = await GET(url)
    return result
}
export const updateById = async (residence_id, project_id, house_number, house_plan, building, room_number, floor, is_active, uuid) => {
    const url = routes.root_path + '/' + uuid
    const jsonData = {
        residence_id: residence_id,
        project_id: project_id,
        house_number: house_number,
        house_plan: house_plan,
        building: building,
        room_number: room_number,
        floor: floor,
        is_active: is_active
    }
    const result = await PUT(url, jsonData)
    return result
}
export const removeById = async (uuid) => {
    const url = routes.root_path + '/' + uuid
    const result = await DELETE(url)
    return result
}