import React, { useState, useEffect } from "react";
import Header from "../../../template/Header";
import Menu from "../../../template/Menu";
import Footer from "../../../template/Footer";
import { Row, Col, Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import UploadIMG from "../../../components/uploads/UploadIMG";
import HeadTitle from "../../../template/HeadTitle";
import InputData from "../../../components/Input/index";
import { useTranslation } from "react-i18next";
import CustomSweetAlert from "../../../components/Sweetalert/index";
import {
  colorSaveBtn,
  hoverSaveBtn,
  colorCancleBtn,
  hoverCancleBtn,
  colorUploadBtn,
  hoverUploadBtn,
} from "../../../components/ThemeConfig/btnConfig";
import { useButtonDynamicStyles } from "../../../components/useDynamic/Button_DynamicStyle";
import { colorPrimary } from "../../../components/ThemeConfig/templateConfig";
import { useTemplateDynamicStyles } from "../../../components/useDynamic/template_DynamicStyle";
import { DataDropdownBrand } from "../../../components/dataMockup/DataMockDropdown";
import DropdownSearch from "../../../components/DropdownSearch/DropdownSearch.jsx";
import {
  create,
  getListById,
  updateById,
} from "../../../api/projectRoutes";

import {
  getLists as getBrands
} from "../../../api/brandRoutes";
import dayjs from "dayjs";
import { urlToFile, urlToBase64 } from "../../../utils/imageUtil"

const buttonConfig = {
  colorSaveBtn: colorSaveBtn,
  hoverSaveBtn: hoverSaveBtn,
  colorCancleBtn: colorCancleBtn,
  hoverCancleBtn: hoverCancleBtn,
  colorUploadBtn: colorUploadBtn,
  hoverUploadBtn: hoverUploadBtn,
};
const templateConfig = {
  colorPrimary: colorPrimary,
};
function AddEditData() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get("id");
  useButtonDynamicStyles(buttonConfig);
  useTemplateDynamicStyles(templateConfig);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({
    Project_id: "",
    Project_EN: "",
    Project_TH: "",
    brandName: "",
    Website: "",
    status: true,
    image: [],
  });
  
  const [error, setError] = useState({
    error_EN: false,
    error_TH: false,
    error_Logo: false,
  });
  const [masterDataBrand, setMasterDataBrand] = useState([])
  const [status, setStatus] = useState("create");
  const [showAlert, setShowAlert] = useState(false);
  const [search, setSearch] = useState();

  
  const navigatePage = (page) => {
    navigate("/" + page);
  };

  const handleSelectedImages = (selectedImages) => {
    setData((prevState) => ({
      ...prevState,
      image: selectedImages, // Updating image array in state
    }));
  };

  // Removing a specific image by index from the state
  const handleSelectedRemoveImg = () => {
    setData((prevState) => ({
      ...prevState, // Retain all other state properties
      image: [], // Ensure newImages is an array
    }));
  };
  const inputSetData = (value, language) => {
    const englishOnly = value.replace(/[^A-Za-z0-9\s]/g, "");
    const thaiOnly = value.replace(/[^ก-๙0-9\s]/g, "");
    if (language === "en") {
      setData((prevData) => ({
        ...prevData,
        Project_EN: englishOnly,
      }));
    } else {
      setData({
        ...data,
        Project_TH: thaiOnly,
      });
    }
    // error_EN: language === "en" && englishOnly === "" ? true : false,
    // error_TH: language !== "en" && thaiOnly === "" ? true : false,
    setError((prevError) => {
      let error_EN;
      let error_TH;

      if (language === "en") {
        error_EN = englishOnly === "";
      } else if (thaiOnly) {
        error_EN = false;
      } else {
        error_EN = prevError.error_EN;
      }

      if (language !== "en") {
        error_TH = thaiOnly === "";
      } else if (englishOnly) {
        error_TH = false;
      } else {
        error_TH = prevError.error_TH;
      }

      return {
        ...prevError,
        error_EN: error_EN,
        error_TH: error_TH,
        error_Logo: error_EN && error_TH,
      };
    });
  };
  const handleConfirm = async (status) => {
    
    setShowAlert(false); // Hide the alert after confirmation
    try {
      const response = editId
        ? await updateById(editId, data)
        : await create(data);
      setShowAlert(true);
      setStatus("success");
      if (status === "Close") {
        navigatePage("masterDataProject");
      }

    } catch (error) { }
  };

  // Function to handle cancellation
  const handleCancel = async (status) => {
    setShowAlert(false); // Hide the alert after cancellation
    if (!editId) {
     
      // setData((prevState) => ({
      //   ...prevState, // Retain all other state properties
      //   Brand_id: "",
      //   BrandName_EN: "",
      //   BrandName_TH: "",
      //   Website: "",
      //   status: true,
      //   image: [], // Ensure newImages is an array
      // }));

      setError({
        error_EN: false,
        error_TH: false,
      });
    }
  };
  const handleSubmit = () => {
    setShowAlert(true); // Show the alert when this function is called
  };
  const getDataByID = async () => {
    try {
      const response = await getListById(editId);
      const projectDetail = response?.data?.data
      const toBase64 = await urlToBase64(projectDetail.logo_url)
      setStatus("edit");
      setData({
        ...data,
        Project_id: projectDetail.uuid,
        Project_EN: projectDetail.project_en,
        Project_TH: projectDetail.project_th,
        status: projectDetail.is_active,
        // TODO Mockup wait backend send data to frontend
        brandName: "a5e16fad-53f1-4d5f-bec0-e3a2864f3407",
        image: [{
          file: [],
          id: '-1',
          src: projectDetail.logo_url
        }],
        created_at: projectDetail.created_at ? dayjs(projectDetail.created_at).format('YYYY-MM-DD HH:mm') : '-',
        updated_at: projectDetail.updated_at ? dayjs(projectDetail.updated_at).format('YYYY-MM-DD HH:mm') : '-',
        create_by: projectDetail.created_by ?? '-',
        updated_by: projectDetail.updated_by ?? '-'
      });
    } catch (error) { }
  };

  const fetchBrand = async () => {
    const resultBrands = await getBrands()
    const mapFromRaw = resultBrands.data.data.map((item => {
      return {
        label: item.brand_th,
        value: item.uuid
      }
    }));
    setMasterDataBrand(mapFromRaw)
  }
  useEffect(() => {
    if (editId) {
      getDataByID();
    }
    fetchBrand()
  }, []);

  return (
    <>
      {isLoading ? <Loading /> : ""}{" "}
      {showAlert && (
        <CustomSweetAlert
          data={{
            showCancel: status === "success" ? false : true,
            reverseButtons: true,
            confirmBtnText:
              {
                create: "Yes",
                edit: "Yes",
                success: "Close",
              }[status] || "Delete",
            cancelBtnText: "Cancel",
            icon: "",
            title:
              {
                create: "WARNING",
                edit: "WARNING",
                success: "SUCCESS",
              }[status] || "WARNING",
            message:
              {
                create: "คุณต้องการบันทึกหรือไม่?",
                edit: "คุณต้องการบันทึกการเปลี่ยนแปลงหรือไม่?",
                success: "Data saved successfully",
              }[status] || "Are you sure you want to delete this data?",
            onConfirm: handleConfirm,
            onCancel: handleCancel,
            colorBorder:
              { create: "#F7BC0B", edit: "#F7BC0B", success: "#28CC7D" }[
              status
              ] || "#E32A29",
            colorBtnConfirm:
              { create: "#F7BC0B", edit: "#F7BC0B", success: "#28CC7D" }[
              status
              ] || "#E32A29",
            colorBtnCancel: "#E0E0E0",
          }}
        />
      )}
      <Header parentmenu={"Master Data"} />
      <Menu parentmenu={"MasterData"} activemenu={"Project"} />
      <div className="content-wrapper">
        <div className="container-fluid  pdb-page">
          <HeadTitle
            title="รายละเอียดโครงการ"
            status={false}
            path={""}
            pathImport={""}
            pathExport={""}
          />
          <div className="box-white mt-4">
            <div className="px-3">
              {" "}
              <Row className="mt-3">
                {
                  editId &&

                  <Col md={6} xl={6}>
                    <Row>
                      <Col xs={4} md={5} xl={5} className="align-self-center">
                        <span className="fs-15">Project ID</span>
                      </Col>
                      <Col xs={8} md={7} xl={7}>
                        <InputData
                          type="text"
                          className="inp-data"
                          id="Project_id"
                          placeholder="Project ID"
                          value={data.Project_id}
                          onChange={(event) =>
                            setData({
                              ...data,
                              Project_id: event.target.value,
                            })
                          }
                          disable={true}
                        />
                      </Col>
                    </Row>{" "}
                  </Col>
                }
                {" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Brand</span>
                    </Col>

                    <Col xs={8} md={7} xl={7}>
                      {" "}
                      <DropdownSearch
                        placeholder={"Brand"}
                        value={data?.brandName ? data.brandName : ''}
                        onChange={(e) => {
                          setData({
                            ...data,
                            brandName: e.value,
                          });
                        }}
                        options={masterDataBrand}
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Project Name (EN)</span>
                      &nbsp; <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Project_EN"
                        placeholder="Project Name (EN)"
                        value={data.Project_EN || ""}
                        onChange={(event) => {
                          inputSetData(event.target.value, "en");
                        }}
                      // disable={data.Project_TH ? true : false}
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Project Name (TH)</span>
                      &nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Project_TH"
                        placeholder="Project Name (TH)"
                        value={data.Project_TH || ""}
                        onChange={(event) => {
                          inputSetData(event.target.value, "th");
                        }}
                      // disable={data.Project_EN ? true : false}
                      />
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>{" "}
              <Row>
                <Col md={6} xl={6}>
                  <Row>
                    <Col
                      xs={4}
                      md={5}
                      xl={5}
                      className="align-self-center"
                    ></Col>
                    <Col xs={8} md={7} xl={7}>
                      <p
                        className={
                          error.error_EN
                            ? "fs-14 mt-1 text-danger font-weight-bold"
                            : "d-none"
                        }
                      >
                        {" "}
                        {t("master_Project.project_en")}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} xl={6}>
                  {" "}
                  <Row>
                    <Col
                      xs={4}
                      md={5}
                      xl={5}
                      className="align-self-center"
                    ></Col>
                    <Col xs={8} md={7} xl={7}>
                      <p
                        className={
                          error.error_TH
                            ? "fs-14 mt-1 text-danger font-weight-bold"
                            : "d-none"
                        }
                      >
                        {" "}
                        {t("master_Project.project_th")}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Logo Project</span>
                      &nbsp;<span className="text-danger">*</span>
                    </Col>{" "}
                    <Col xs={8} md={7} xl={7}>
                      <UploadIMG
                        limit={1}
                        onSelectedImages={handleSelectedImages}
                        images={data.image}
                        removeImg={handleSelectedRemoveImg}
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col
                      xs={4}
                      md={5}
                      xl={5}
                      className="align-self-center"
                    ></Col>
                    <Col xs={8} md={7} xl={7}>
                      <p
                        className={
                          error.error_Logo
                            ? "fs-14 mt-1 text-danger font-weight-bold"
                            : "d-none"
                        }
                      >
                        {" "}
                        {t("master_Brand.brand_logo")}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {editId && (
                <>
                  <Row className="mt-4">
                    <Col md={6} xl={6}>
                      <Row>
                        <Col xs={4} md={5} xl={5} className="align-self-center">
                          <span className="fs-15">Create at</span>
                        </Col>
                        <Col xs={8} md={7} xl={7}>
                          {/* <span className="fs-15">2024-09-25 10:00</span> */}
                          <span className="fs-15">{data.created_at}</span>
                        </Col>
                      </Row>{" "}
                    </Col>{" "}
                    <Col md={6} xl={6}>
                      <Row>
                        <Col xs={4} md={5} xl={5} className="align-self-center">
                          <span className="fs-15">Create by</span>
                        </Col>
                        <Col xs={8} md={7} xl={7}>
                          <span className="fs-15">{data.create_by}</span>
                        </Col>
                      </Row>{" "}
                    </Col>
                  </Row>{" "}
                  <Row className="mt-3">
                    <Col md={6} xl={6}>
                      <Row>
                        <Col xs={4} md={5} xl={5} className="align-self-center">
                          <span className="fs-15">Update at</span>
                        </Col>
                        <Col xs={8} md={7} xl={7}>
                          <span className="fs-15">{data.updated_at}</span>
                        </Col>
                      </Row>{" "}
                    </Col>{" "}
                    <Col md={6} xl={6}>
                      <Row>
                        <Col xs={4} md={5} xl={5} className="align-self-center">
                          <span className="fs-15">Update by</span>
                        </Col>
                        <Col xs={8} md={7} xl={7}>
                          <span className="fs-15">{data.updated_by}</span>
                        </Col>
                      </Row>{" "}
                    </Col>
                  </Row>{" "}
                </>
              )}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Status</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <Form>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`}>
                            <Form.Check
                              className={
                                data.status === true
                                  ? "fs-15 radio-item checked"
                                  : "fs-15 radio-item"
                              }
                              inline
                              label="Active"
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                              checked={data.status}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  status: true,
                                })
                              }
                            />
                            <Form.Check
                              className={
                                data.status === false
                                  ? "fs-15 radio-item checked"
                                  : "fs-15 radio-item "
                              }
                              inline
                              label="Inactive"
                              name="group1"
                              type={type}
                              id={`inline-${type}-2`}
                              checked={!data.status}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  status: false,
                                })
                              }
                            />
                          </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>
            </div>
            <Row className="d-flex mt-4">
              <Col xs={6} md={"auto"} className="d-flex justify-content-start ">
                <button
                  className={"btn-cancle text-white"}
                  onClick={(e) => {
                    navigatePage("masterDataProject");
                  }}
                >
                  Back
                </button>{" "}
                <button
                  className="btn-save"
                  disabled={
                    !data.Project_EN &&
                    !data.Project_TH &&
                    data.image.length < 1
                  }
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  Save
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddEditData;
