import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Header from "../../../template/Header";
import Menu from "../../../template/Menu";
import Footer from "../../../template/Footer";
import { Row, Col, Form } from "react-bootstrap";
import Loading from "../../../components/loading/Loading";
import HeadTitle from "../../../template/HeadTitle";
import InputData from "../../../components/Input/index";
import CustomSweetAlert from "../../../components/Sweetalert/index";
import {
  colorSaveBtn,
  hoverSaveBtn,
  colorCancleBtn,
  hoverCancleBtn,
  colorUploadBtn,
  hoverUploadBtn,
} from "../../../components/ThemeConfig/btnConfig";
import { useButtonDynamicStyles } from "../../../components/useDynamic/Button_DynamicStyle";
import { colorPrimary } from "../../../components/ThemeConfig/templateConfig";
import { useTemplateDynamicStyles } from "../../../components/useDynamic/template_DynamicStyle";
import {
  textPrimary,
  textSecond,
} from "../../../components/ThemeConfig/textConfig";
import { useTextDynamicStyles } from "../../../components/useDynamic/text_DynamicStyle";
import DropdownSearch from "../../../components/DropdownSearch/DropdownSearch.jsx";
import { create, updateById, getListById } from "../../../api/unitRoutes"
import { getLists as getResidentType } from "../../../api/residenceTypeRoutes"
import { getLists as getproject } from "../../../api/projectRoutes"
import { getLists as getbrand } from "../../../api/brandRoutes"
const buttonConfig = {
  colorSaveBtn: colorSaveBtn,
  hoverSaveBtn: hoverSaveBtn,
  colorCancleBtn: colorCancleBtn,
  hoverCancleBtn: hoverCancleBtn,
  colorUploadBtn: colorUploadBtn,
  hoverUploadBtn: hoverUploadBtn,
};
const templateConfig = {
  colorPrimary: colorPrimary,
};
const textConfig = {
  textPrimary: textPrimary,
  textSecond: textSecond,
};
function AddEditData() {
  useButtonDynamicStyles(buttonConfig);
  useTemplateDynamicStyles(templateConfig);
  useTextDynamicStyles(textConfig);
  const { uuid1 } = useParams();
  const [masterData, setMasterData] = useState({
    projects: [],
    residentType: [],
    brands: []
  })

  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({
    unit_id: "",
    brand: "",
    residence_type: "",
    project: "",
    house_number: "",
    house_plan: "",
    room_plan: "",
    floor: "",
    status: true,
    Phone: "",
    room_number: ""
  });

  const [showAlert, setShowAlert] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get("id");
  console.log("editId : ", editId);
  // const [status, setStatus] = useState(editId ? "edit" : "create");
  const [status, setStatus] = useState("success");
  const navigatePage = (page) => {
    navigate("/" + page);
  };
  const handleSubmit = () => {
    setShowAlert(true); // Show the alert when this function is called
  };
  const handleConfirm = async () => {
    if (editId) {
      const response = await updateById(
        data.residence_type,
        data.project,
        data.house_number,
        data.house_plan,
        '-',
        data.room_number,
        data.floor,
        data.status,
        editId
      )
      console.log(response);
      
    } else {
      const response = await create(
        data.residence_type,
        data.project,
        data.house_number,
        data.house_plan,
        '-',
        data.room_number,
        data.floor,
        data.status
      )
      console.log(response);
    }
    setShowAlert(false); // Hide the alert after confirmation
  };

  // Function to handle cancellation
  const handleCancel = (status) => {
    console.log(status); // Handle the cancellation
    setShowAlert(false); // Hide the alert after cancellation
  };
  useEffect(() => {
    fetchDataInitial()
  }, []);

  const fetchDataInitial = async () => {
    const fetchAndTransform = async (fetchFunction, labelKey, valueKey) => {
      const response = await fetchFunction();
      return response?.data?.data?.map((item) => ({
        label: item[labelKey],
        value: item[valueKey],
      }));
    };

    const [residentType, projects, brands] = await Promise.all([
      fetchAndTransform(getResidentType, "residence_th", "uuid"),
      fetchAndTransform(getproject, "project_th", "uuid"),
      fetchAndTransform(getbrand, "brand_th", "uuid"),
    ]);

    setMasterData({
      ...masterData,
      residentType,
      projects,
      brands,
    });
    if (editId) {

      const response = await getListById(editId)
      const results = response.data.data
      setData({
        ...data,
        unit_id: results?.uuid,
        brand: results?.brand,
        residence_type: results?.residence_type,
        project: results?.project,
        house_number: results?.house_number,
        house_plan: results?.house_plan,
        room_plan: results?.room_plan,
        floor: results?.floor,
        status: results?.is_active,
        room_number: results?.room_number
      })

    }
  };

  return (
    <>
      {/* <Loading /> */}
      {isLoading ? <Loading /> : ""}
      {showAlert && (
        <CustomSweetAlert
          data={{
            showCancel: status === "success" ? false : true,
            reverseButtons: true,
            confirmBtnText:
              {
                edit: "Yes",
                success: "Close",
              }[status] || "Delete",
            cancelBtnText: "Cancel",
            icon: "",
            title:
              {
                edit: "WARNING",
                success: "SUCCESS",
              }[status] || "DELETE",
            message:
              {
                edit: "Do you want to save the changes?",
                success: "Data saved successfully",
              }[status] || "Are you sure you want to delete this data?",
            onConfirm: handleConfirm,
            onCancel: handleCancel,
            colorBorder:
              {
                edit: "#F7BC0B",
                success: "#28CC7D",
              }[status] || "#E32A29",
            colorBtnConfirm:
              {
                edit: "#F7BC0B",
                success: "#28CC7D",
              }[status] || "#E32A29",
            colorBtnCancel: "#E0E0E0",
          }}
        />
      )}
      <Header parentmenu={"Unit Management"} />
      <Menu parentmenu={"Unit Management"} activemenu={"Unit Management"} />
      <div className="content-wrapper">
        <div className="container-fluid  pdb-page">
          <HeadTitle
            title="รายละเอียดข้อมูลยูนิต"
            status={false}
            path={""}
            pathImport={""}
            pathExport={""}
          />
          <div className="box-white mt-4">
            <div className="px-3">
              {" "}
              <Row className="mt-3">
                {
                  editId &&
                  <Col md={6} xl={6}>
                    <Row>
                      <Col xs={4} md={5} xl={5} className="align-self-center">
                        <span className="fs-15">Unit ID</span>
                      </Col>
                      <Col xs={8} md={7} xl={7}>
                        <InputData
                          type="text"
                          className="inp-data"
                          id="unit_id"
                          placeholder="Unit ID"
                          value={data.unit_id}
                          onChange={(event) =>
                            setData({
                              ...data,
                              unit_id: event.target.value,
                            })
                          }
                          disable
                        />
                      </Col>
                    </Row>{" "}
                  </Col>
                }
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Residence Type</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      {" "}
                      <DropdownSearch
                        placeholder={"Residence Type"}
                        value={data.residence_type}
                        onChange={(e) => {
                          setData({
                            ...data,
                            residence_type: e.value,
                          });
                        }}
                        options={masterData.residentType}
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Brand</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      {" "}
                      <DropdownSearch
                        placeholder={"Brand"}
                        value={data.brand}
                        onChange={(event) =>
                          setData({
                            ...data,
                            brand: event.value,
                          })
                        }
                        options={masterData.brands}
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Project</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <DropdownSearch
                        placeholder={"Project"}
                        value={data.project}
                        onChange={(event) =>
                          setData({
                            ...data,
                            project: event.value,
                          })
                        }
                        options={masterData.projects}
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Owner</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="Owner"
                        placeholder="Owner"
                        value={data.Owner}
                        onChange={(event) =>
                          setData({
                            ...data,
                            Owner: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">House Number</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="HouseNumber"
                        placeholder="House Number"
                        value={data.house_number}
                        onChange={(event) =>
                          setData({
                            ...data,
                            house_number: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">House Plan</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="HousePlan"
                        placeholder="House Plan"
                        value={data.house_plan}
                        onChange={(event) =>
                          setData({
                            ...data,
                            house_plan: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Room Number</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="RoomNumber"
                        placeholder="Room Number"
                        value={data.room_number}
                        onChange={(event) =>
                          setData({
                            ...data,
                            room_number: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Floor</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <InputData
                        type="text"
                        className="inp-data"
                        id="floor"
                        placeholder="Floor"
                        value={data.floor}
                        onChange={(event) =>
                          setData({
                            ...data,
                            floor: event.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>{" "}
                </Col>{" "}
              </Row>{" "}
              <Row className="mt-3">
                <Col md={6} xl={6}>
                  <Row>
                    <Col xs={4} md={5} xl={5} className="align-self-center">
                      <span className="fs-15">Status</span>&nbsp;
                      <span className="text-danger">*</span>
                    </Col>
                    <Col xs={8} md={7} xl={7}>
                      <Form>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`}>
                            <Form.Check
                              className={
                                data.status === true
                                  ? "fs-15 radio-item checked"
                                  : "fs-15 radio-item"
                              }
                              inline
                              label="Active"
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                              checked={data.status}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  status: true,
                                })
                              }
                            />
                            <Form.Check
                              className={
                                data.status === false
                                  ? "fs-15 radio-item checked"
                                  : "fs-15 radio-item "
                              }
                              inline
                              label="Inactive"
                              name="group1"
                              type={type}
                              id={`inline-${type}-2`}
                              checked={!data.status}
                              onChange={(event) =>
                                setData({
                                  ...data,
                                  status: false,
                                })
                              }
                            />
                          </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>{" "}
            </div>
            <Row className="d-flex mt-4">
              <Col xs={6} md={"auto"} className="d-flex justify-content-start ">
                <button
                  className={"btn-cancle text-white"}
                  onClick={(e) => {
                    navigatePage(
                      "/workspace/" + uuid1 + "/masterDataUnitManagement"
                    );
                  }}
                >
                  Back
                </button>{" "}
                <button
                  className="btn-save"
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  Save
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddEditData;
